footer
    height: 30vh
    width: 79%
    .footer_content
        display: flex
        flex-direction: row
        justify-content: space-between
        padding-top: 40px
        border-top: 1px solid $surface-mixed-600

        h3
            font-family: $subTitleFont 
            color: white
            font-size: 18px
            font-weight: bold
        p
            font-family: $textFont 
            color: $surface-mixed-600

@media (max-width: 990px)
    footer
        margin-left: 5%
        width: 88%
        .footer_content
            display: flex
            flex-direction: row
            justify-content: space-between
            padding-top: 40px
            border-top: 1px solid $surface-mixed-600

            h3
                font-family: $subTitleFont 
                color: white
                font-size: 18px
                font-weight: bold
            p
                font-family: $textFont 
                color: $surface-mixed-600

@media (max-width: 760px)
    footer
        width: 88vw !important
        .footer_content
                div
                    margin-bottom: 20px

                justify-content: start
                flex-direction: column
                padding-top: 40px
                border-top: 1px solid $surface-mixed-600

                h3
                    font-family: $subTitleFont 
                    color: white
                    font-size: 18px
                    font-weight: bold
                a
                
                    p
                        &:hover
                            color: $primary-200 !important
                        font-family: $textFont 
                        color: $surface-mixed-600
