// title font
// @import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap')
// $titleFont : "Monoton", sans-serif

@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap')
$titleFont : "Russo One", sans-serif

//sub title font
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap')
$subTitleFont : "Montserrat", sans-serif

//text font
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap')
$textFont : "Maven Pro", sans-serif