.contact
    padding-top: 150px
    margin-top: 10px !important

    margin-bottom: 40px
    .contact_subtitle
        background-color: $surface-100
        width: fit-content
        padding: 10px 15px
        border-radius: 15px
        display: flex
        align-items: center
        justify-content: space-between
        border: 1px solid $surface-500
        display: flex

        p
            font-size: 16px
            font-family: $subTitleFont
            margin-left: 10px
            color: white
            font-weight: 600

    .contact_title
        margin: 50px 0px
        h2
            width: 80%
            font-family: $titleFont 
            color: white
            font-size: 70px !important

    form
        width: 80%
        .contact_form_info
            display: flex
            input

                font-family: $textFont
                color: white
                font-size: 20px
                background: none
                backdrop-filter: blur(5px)
                padding: 20px
                margin-bottom: 40px
                margin-right: 30px
                width: 90%
                border: none
                border-bottom: 3px solid $primary-400

                &:focus
                    outline: none
                    border-bottom: 3px solid $primary-200
                    

                &::placeholder
                    color: $surface-mixed-600
                    
            .contact_form_info_names
                display: flex
                justify-content: space-between
                width: 50%
                display: flex
                flex-direction: column

            .contact_form_info_contacts
                width: 50%
                display: flex
                align-items: flex-end
                flex-direction: column

        .contact_form_message
            textarea
                resize: none
                height: 200px

                font-family: $textFont
                color: white
                font-size: 20px
                background: none
                backdrop-filter: blur(5px)
                padding: 20px
                margin-bottom: 40px
                margin-right: 30px
                width: 98%
                border: none
                border-bottom: 3px solid $primary-400

                &:focus
                    outline: none
                    border-bottom: 3px solid $primary-200
                    

                &::placeholder
                    color: $surface-mixed-600
        
        button
            img
                margin-left: 20px
                width: 30px
            display: flex
            align-items: center
            justify-content: center
            padding: 15px 40px
            border: none
            border-radius: 15px
            background: $primary-200
            color: white
            font-size: 20px
            font-family: $textFont 
            cursor: pointer

            &:hover
                transform: translateY(-10px) !important


@media (max-width: 1600px)
    .contact
        .contact_subTitle

            p
                font-size: 14px

        .contact_title
            h2
                font-size: 55px !important

        form
            input, textarea, button
                font-size: 15px !important

@media (max-width: 1400px)
    .contact
            .contact_title
                h2
                    font-size: 45px !important

@media (max-width: 1200px)
    .contact
            .contact_title
                h2
                    font-size: 40px !important

@media (max-width: 990px)
    .contact
        padding-top: 100px
        margin-top: 10px !important
        .contact_title
            h2
                font-size: 50px !important
        margin-left: 5%

        .contact_form_info, .contact_form_message
            width: 90vw

@media (max-width: 750px)
    .contact
        .contact_title
            h2
                font-size: 50px !important
    .contact_form_info
        flex-direction: column
        width: 100%
        .contact_form_info_names, .contact_form_info_contacts
            align-items: flex-start !important
            width: 108% !important
            margin: 0px !important

