.main
    background-color: $surface-100
    display: flex
    position: relative

    .main_cardSection 
        display: flex
        justify-content: center
        align-items: center
        position: sticky
        top: 0px
        height: 100vh

@media (max-width: 990px)
    .main
        display: flex
        flex-direction: column

        .main_cardSection
            position: relative
            height: auto !important