

/** SCSS DARK THEME PRIMARY COLORS */ 
$primary-100:  #382bf0
$primary-200:  #5e43f3
$primary-300:  #7a5af5    
$primary-400:  #9171f8
$primary-500:  #a688fa
$primary-600:  #ba9ffb
/** SCSS DARK THEME SURFACE COLORS */ 
$surface-100:  #121212
$surface-200:  #282828
$surface-300:  #3f3f3f
$surface-400:  #575757
$surface-500:  #717171

/** SCSS DARK THEME MIXED SURFACE COLORS */ 
$surface-mixed-100:  #1a1625 
$surface-mixed-200:  #2f2b3a 
$surface-mixed-300:  #46424f 
$surface-mixed-400:  #5e5a66 
$surface-mixed-500:  #76737e 
$surface-mixed-600:  #908d96
