/** SCSS DARK THEME PRIMARY COLORS */ 
/** SCSS DARK THEME SURFACE COLORS */ 
/** SCSS DARK THEME MIXED SURFACE COLORS */ 
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap");
*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  z-index: 0;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

* {
  text-decoration: none;
  transition: 0.5s;
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 990px) {
  body, html {
    max-width: 100%;
    overflow-x: hidden;
  }
}
body, html {
  overflow-x: clip;
}

@keyframes drawLogo {
  0% {
    stroke-dasharray: 4500;
  }
  100% {
    stroke-dasharray: 0;
  }
}
@keyframes toUp {
  90% {
    top: 0px;
  }
  100% {
    top: -50vh;
  }
}
@keyframes toBottom {
  90% {
    bottom: 0px;
  }
  100% {
    bottom: -50vh;
    display: none;
  }
}
@keyframes openWord {
  0% {
    color: rgba(0, 0, 0, 0);
    letter-spacing: -1em;
  }
  10% {
    letter-spacing: -0.99em;
    color: rgba(0, 0, 0, 0);
  }
  25% {
    color: rgba(0, 0, 0, 0);
  }
  100% {
    color: white;
    letter-spacing: 1px;
  }
}
body {
  background-color: #121212;
}

.loading .loadingUp, .loading .loadingBottom {
  position: absolute;
  width: 100vw;
  height: 50vh;
  background-color: #282828;
}
.loading .loadingUp {
  animation: toUp 7s ease;
  top: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.loading .loadingUp svg {
  height: 150px;
  width: 150px;
}
.loading .loadingUp .svgLogo {
  margin: 30px;
  height: 150px;
  width: 150px;
  stroke: white;
  stroke-width: 80px;
  stroke-dasharray: 0;
  animation: drawLogo 5s ease;
}
.loading .loadingBottom {
  bottom: 0px;
  animation: toBottom 7s ease;
  display: flex;
  align-items: flex-top;
  justify-content: center;
}
.loading .loadingBottom h1 {
  font-size: calc(30px + 1vw);
  margin: 30px;
  font-family: "Russo One", sans-serif;
  color: #5e43f3;
}
.loading .loadingBottom h1 span {
  letter-spacing: 1px;
  color: transparent;
  overflow: hidden;
  color: white;
  animation: openWord 8s ease;
}

@media (max-width: 750px) {
  .loading .loadingUp .svgLogo {
    margin-bottom: 25% !important;
  }
}
.main {
  background-color: #121212;
  display: flex;
  position: relative;
}
.main .main_cardSection {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0px;
  height: 100vh;
}

@media (max-width: 990px) {
  .main {
    display: flex;
    flex-direction: column;
  }
  .main .main_cardSection {
    position: relative;
    height: auto !important;
  }
}
@keyframes appear {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.devCard {
  height: auto !important;
  padding: 50px;
  margin: 80px;
  background: #2f2b3a;
  border: #908d96 1px solid;
  border-radius: 20px;
}
.devCard .devCard_logo {
  display: flex;
  align-items: center;
}
.devCard .devCard_logo div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.devCard .devCard_logo svg {
  width: 40px;
  height: -moz-min-content;
  height: min-content;
}
.devCard .devCard_logo h2 {
  margin-left: 20px;
  color: #5e43f3;
  font-family: "Russo One", sans-serif;
  font-size: 30px;
}
.devCard .devCard_logo h2 span {
  color: white;
}
.devCard .devCard_devImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 35px;
}
.devCard .devCard_devImage img {
  border-radius: 20px;
  width: 300px;
}
.devCard .devCard_stack, .devCard .devCard_localization {
  margin-bottom: 20px;
}
.devCard .devCard_stack h3, .devCard .devCard_localization h3 {
  font-size: 10px;
  color: white;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 8px;
}
.devCard .devCard_stack p, .devCard .devCard_localization p {
  font-weight: bold;
  font-size: 18px;
  color: white;
  font-family: "Maven Pro", sans-serif;
}
.devCard .devCard_buttons {
  margin-top: 40px;
}
.devCard .devCard_buttons .devCard_buttons_top {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.devCard .devCard_buttons .devCard_buttons_top button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border: 1px solid #908d96;
  cursor: pointer;
  background: none;
  border-radius: 10px;
  transition: 0.5s;
}
.devCard .devCard_buttons .devCard_buttons_top button img {
  width: 20px;
}
.devCard .devCard_buttons .devCard_buttons_top button:hover {
  transition: 0.5s;
  transform: translateY(-10px) scale(1.2);
}
.devCard .devCard_buttons .devCard_buttons_down button {
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  border: none;
  background-color: #5e43f3;
  color: white;
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: 0.5s;
}
.devCard .devCard_buttons .devCard_buttons_down button:hover {
  transition: 0.5s;
  transform: translateY(-10px);
}

@media (max-width: 1400px) {
  .devCard {
    padding: 20px;
    height: 90vh;
    margin-left: 50px;
  }
  .devCard .devCard_logo h2 {
    margin-left: 20px;
    font-size: 25px;
  }
  .devCard .devCard_devImage {
    margin-bottom: 70px;
    margin-top: 10px;
  }
  .devCard .devCard_devImage img {
    width: 270px;
  }
  .devCard .devCard_stack, .devCard .devCard_localization {
    margin-bottom: 60px;
  }
  .devCard .devCard_buttons {
    margin-top: 70px;
  }
  .devCard .devCard_buttons .devCard_buttons_top button {
    width: 35px;
    height: 35px;
  }
  .devCard .devCard_buttons .devCard_buttons_down button {
    margin-top: 10px;
  }
}
@media (max-width: 990px) {
  .devCard {
    height: auto;
    margin: 20px 0px 0px 0px !important;
    width: 90vw;
    border-radius: 40px;
    padding: 40px;
  }
  .devCard .devCard_logo svg {
    width: 50px;
    margin-right: 20px;
  }
  .devCard .devCard_logo h2 {
    margin: 0px;
    font-size: 35px;
  }
  .devCard .devCard_devImage {
    margin-bottom: 70px;
    margin-top: 20px;
  }
  .devCard .devCard_devImage img {
    width: 100%;
  }
  .devCard .devCard_stack, .devCard .devCard_localization {
    margin-bottom: 30px;
  }
  .devCard .devCard_stack h3, .devCard .devCard_localization h3 {
    font-size: 16px;
  }
  .devCard .devCard_stack p, .devCard .devCard_localization p {
    font-size: 25px;
  }
  .devCard .devCard_buttons {
    margin-top: 80px;
  }
  .devCard .devCard_buttons .devCard_buttons_top button {
    width: 55px;
    height: 55px;
  }
  .devCard .devCard_buttons .devCard_buttons_top button img {
    width: 25px;
  }
  .devCard .devCard_buttons .devCard_buttons_down button {
    height: 60px;
    margin-top: 20px;
    font-size: 20px;
  }
}
@media (max-width: 750px) {
  .devCard {
    width: 95vw;
  }
  .devCard .devCard_logo h2 {
    font-size: calc(18px + 1.3vw);
  }
  .devCard .devCard_devImage {
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .devCard .devCard_devImage img {
    width: 100%;
  }
  .devCard .devCard_stack, .devCard .devCard_localization {
    margin-bottom: 30px;
  }
  .devCard .devCard_stack h3, .devCard .devCard_localization h3 {
    font-size: calc(10px + 1.3vw);
  }
  .devCard .devCard_stack p, .devCard .devCard_localization p {
    font-size: calc(16px + 1.3vw);
  }
  .devCard .devCard_buttons {
    margin-top: 50px;
  }
  .devCard .devCard_buttons .devCard_buttons_top button {
    width: 40px;
    height: 40px;
  }
  .devCard .devCard_buttons .devCard_buttons_top button img {
    width: 20px;
  }
  .devCard .devCard_buttons .devCard_buttons_down button {
    height: 50px;
    margin-top: 20px;
    font-size: 17px;
  }
}
.header {
  display: flex;
  z-index: 1000;
  margin-top: 65px;
  position: fixed;
}
.header .header_options {
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  transition: 0.5s;
  border-radius: 15px;
  font-size: 18px;
  margin: 10px 20px 10px 10px;
  border: 2px solid #908d96;
}
.header .header_options:hover {
  transform: scale(1.05) translateY(-5px);
  transition: 0.5s;
}
.header .header_options a {
  transition: 0.5s;
  padding: 15px 25px;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
}
.header .header_options a img {
  display: none;
}

@media (max-width: 1600px) {
  .header_options a {
    font-size: 15px;
  }
}
@media (max-width: 1200px) {
  .header_options {
    border-radius: 30px !important;
  }
  .header_options a {
    padding: 15px !important;
  }
  .header_options a span {
    display: none;
  }
  .header_options a img {
    display: flex !important;
  }
}
@media (max-width: 990px) {
  .header {
    z-index: 10000;
    width: -moz-min-content;
    width: min-content;
    border: 1px solid #908d96;
    border-radius: 20px !important;
    position: fixed;
    bottom: 20px;
    left: calc(50vw - 135px);
    display: flex;
    margin-top: 65px;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
  }
  .header .header_options {
    margin: 0px !important;
    border: none;
  }
  .header .header_options:hover {
    transform: scale(1) translateY(0px);
  }
  .header .header_options a {
    transition: 0.5s;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
  }
}
.hey {
  z-index: -1;
  margin-top: 140px !important;
  margin-left: 10px;
  height: 76vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.hey .hey_subTitle {
  background-color: #121212;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #717171;
  display: flex;
}
.hey .hey_subTitle p {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  margin-left: 10px;
  color: white;
  font-weight: 600;
}
.hey .hey_description {
  margin: 50px 0px;
}
.hey .hey_description h2 {
  font-family: "Russo One", sans-serif;
  color: white;
  font-size: 70px;
}
.hey .hey_buttons {
  display: flex;
}
.hey .hey_buttons button {
  font-family: "Maven Pro", sans-serif;
  padding: 20px 40px;
  border-radius: 30px;
  border: none;
  margin-right: 10px;
  font-size: 20px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  cursor: pointer;
}
.hey .hey_buttons button:hover {
  transform: translateY(-10px) scale(1.1);
  transition: 0.5s;
}
.hey .hey_buttons .hey_buttons-resume {
  color: white;
  font-size: 20px;
}
.hey .hey_buttons .hey_buttons-resume img {
  margin-left: 10px;
}
.hey .hey_buttons .hey_buttons-works {
  color: white;
  font-size: 20px;
  background: #121212;
  border: 1px solid #908d96;
}
.hey .hey_buttons .hey_buttons-works img {
  margin-left: 10px;
}

@media (max-width: 1600px) {
  .hey .hey_subTitle p {
    font-size: 14px;
  }
  .hey .hey_description h2 {
    font-size: 55px;
  }
  .hey .hey_buttons .hey_buttons-resume {
    font-size: 17px;
  }
  .hey .hey_buttons .hey_buttons-works {
    font-size: 17px;
  }
}
@media (max-width: 1600px) {
  .hey .hey_description h2 {
    font-size: 45px;
  }
}
@media (max-width: 990px) {
  .hey {
    margin-top: 50px;
    margin-left: 5% !important;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .hey .hey_subTitle {
    background-color: #121212;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 15px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #717171;
    display: flex;
  }
  .hey .hey_subTitle p {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    margin-left: 10px;
    color: white;
    font-weight: 600;
  }
  .hey .hey_description {
    margin: 50px 0px;
  }
  .hey .hey_description h2 {
    font-family: "Russo One", sans-serif;
    color: white;
    font-size: 50px;
  }
}
@media (max-width: 750px) {
  .hey .hey_description h2 {
    font-size: 40px;
  }
}
@media (max-width: 575px) {
  .hey {
    margin-top: 50px !important;
    padding: 5px 0px;
  }
  .hey .hey_buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .hey .hey_buttons a {
    width: 100%;
  }
  .hey .hey_buttons button {
    width: 95%;
    padding: 0px;
    font-size: 30px;
    border-radius: 50px;
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    cursor: pointer;
  }
  .hey .hey_buttons button:hover {
    transform: translateY(0px) scale(1);
    transition: 0.5s;
  }
  .hey .hey_buttons button {
    margin-left: 0px;
  }
  .hey .hey_buttons .hey_buttons-resume {
    color: white;
    font-size: 20px;
    border: 1px solid #908d96;
  }
  .hey .hey_buttons .hey_buttons-resume img {
    margin-left: 10px;
  }
  .hey .hey_buttons .hey_buttons-works {
    margin-bottom: 20px;
    color: white;
    font-size: 20px;
    background: #121212;
    border: 1px solid #908d96;
  }
  .hey .hey_buttons .hey_buttons-works img {
    margin-left: 10px;
  }
}
.projects {
  padding-top: 150px;
  margin-top: 10px !important;
}
.projects .projects_subtitle {
  background-color: #121212;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #717171;
  display: flex;
}
.projects .projects_subtitle p {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  margin-left: 10px;
  color: white;
  font-weight: 600;
}
.projects .projects_title {
  margin: 50px 0px;
}
.projects .projects_title h2 {
  font-family: "Russo One", sans-serif;
  color: white;
  font-size: 70px;
}
.projects .projects_projects {
  display: flex;
  flex-wrap: wrap;
}
.projects .projects_projects .projectCard {
  cursor: pointer;
  margin: 30px 60px 30px 0px;
  width: 40%;
  border: 1px solid #717171;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.projects .projects_projects .projectCard:hover {
  transform: scale(1.05);
}
.projects .projects_projects .projectCard .projectCard_image {
  margin: 20px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projects .projects_projects .projectCard .projectCard_image img {
  border-radius: 100%;
  width: 40%;
}
.projects .projects_projects .projectCard .projectCard_techs {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.projects .projects_projects .projectCard .projectCard_techs .projectCard_techs_tech {
  padding: 10px 25px;
  font-size: 13px;
  color: white;
  border: 1px solid #717171;
  border-radius: 10px;
  font-family: "Maven Pro", sans-serif;
  margin-right: 25px;
  margin-bottom: 20px;
}
.projects .projects_projects .projectScreen {
  animation: open 1s ease-in-out forwards;
  z-index: 100000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  background-color: rgba(18, 18, 18, 0.568627451);
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.projects .projects_projects .projectScreen * {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 0s;
}
.projects .projects_projects .projectScreen .projectScreen_close {
  position: absolute;
  z-index: 100001;
  top: 40px;
  right: 50px;
}
.projects .projects_projects .projectScreen .projectScreen_close button {
  font-family: "Russo One", sans-serif;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 50px;
  background: none;
}
.projects .projects_projects .projectScreen .projectScreen_close button:hover {
  transform: rotate(360deg);
}
.projects .projects_projects .projectScreen .projectScreen_logo img {
  margin: 20px;
  border-radius: 100%;
}
.projects .projects_projects .projectScreen .projectScreen_content .projectScreen_content_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.projects .projects_projects .projectScreen .projectScreen_content .projectScreen_content_title h2 {
  font-weight: bold;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  color: white;
}
.projects .projects_projects .projectScreen .projectScreen_content .projectScreen_content_title img {
  margin-left: 30px;
}
.projects .projects_projects .projectScreen .projectScreen_content_techs {
  margin-top: 20px;
  display: flex;
}
.projects .projects_projects .projectScreen .projectScreen_content_techs .projectScreen_content_techs_tech {
  padding: 10px 25px;
  font-size: 13px;
  color: white;
  border: 1px solid #717171;
  border-radius: 10px;
  font-family: "Maven Pro", sans-serif;
  margin-right: 25px;
  margin-bottom: 20px;
}
.projects .projects_projects .projectScreen .projectScreen_content_description p {
  font-family: "Maven Pro", sans-serif;
  color: white;
  font-size: 17px;
  max-width: 70vw;
}
.projects .projects_projects .invisible {
  display: none;
}
.projects .all_projects {
  margin-top: 20px;
}
.projects .all_projects button {
  padding: 10px 25px;
  color: white;
  background: #5e43f3;
  font-size: 17px;
  font-family: "Maven Pro", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}
.projects .all_projects button:hover {
  transform: translateY(-10px);
}
.projects .all_projects button img {
  margin-left: 20px;
}

@media (max-width: 1600px) {
  .projects .projects_subtitle p {
    font-size: 14px;
  }
  .projects .projects_title h2 {
    font-size: 55px;
  }
  .projects .projects_projects .projectCard {
    width: 39%;
  }
  .projects .projects_projects .projectCard .projectCard_techs .projectCard_techs_tech {
    padding: 10px 10px;
    font-size: 10px;
    color: white;
  }
  .projects .projects_projects .projectCard .projectCard_image img {
    width: 80% !important;
  }
}
@media (max-width: 990px) {
  .projects {
    padding-top: 100px;
    margin-top: 10px !important;
    margin-left: 5% !important;
  }
  .projects .projects_subtitle p {
    font-size: 16px !important;
  }
  .projects .projects_projects .projectCard .projectCard_image img {
    width: 80% !important;
  }
}
@media (max-width: 750px) {
  .projects .projects_title h2 {
    font-size: 40px;
  }
  .projects .projects_projects {
    flex-direction: column;
    align-items: flex-start;
  }
  .projects .projects_projects .projectCard {
    margin: 30px 0px 30px 0px !important;
    width: 95% !important;
    border: 1px solid #717171;
    border-radius: 15px;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  .projects .projects_projects .projectCard .projectCard_image img {
    width: 60% !important;
  }
  .projects .projects_projects .projectCard .projectCard_techs .projectCard_techs_tech {
    padding: 10px 25px;
    font-size: 13px;
    color: white;
  }
  .projects .projectScreen_logo img {
    width: 60vw !important;
  }
  .projects .projectScreen_content .projectScreen_content_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .projects .projectScreen_content .projectScreen_content_title h2 {
    font-weight: bold;
    font-size: 25px !important;
    font-family: "Montserrat", sans-serif;
    color: white;
  }
  .projects .projectScreen_content .projectScreen_content_title img {
    margin-left: 30px;
  }
  .projects .projectScreen_content .projectScreen_content_techs {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
  }
  .projects .projectScreen_content .projectScreen_content_techs .projectScreen_content_techs_tech {
    padding: 10px 25px;
    font-size: 13px;
    color: white;
    border: 1px solid #717171;
    border-radius: 10px;
    font-family: "Maven Pro", sans-serif;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  .projects .projectScreen_content .projectScreen_content_description p {
    font-family: "Maven Pro", sans-serif;
    color: white;
    font-size: 13px !important;
  }
}
@media (max-width: 575px) {
  .projects_projects {
    flex-direction: column;
    align-items: flex-start;
  }
  .projects_projects .projectCard {
    cursor: pointer;
    margin: 30px 0px 30px 0px !important;
    width: 95% !important;
    border: 1px solid #717171;
    border-radius: 15px;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  .projects_projects .projectCard .projectCard_image img {
    width: 90% !important;
  }
  .projects_projects .projectCard .projectCard_techs .projectCard_techs_tech {
    padding: 10px 25px;
    font-size: 10px;
    color: white;
  }
}
@keyframes open {
  0% {
    left: 50%;
    top: 50%;
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  100% {
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.resume {
  padding-top: 150px;
  margin-top: 10px !important;
}
.resume .resume_subtitle {
  background-color: #121212;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #717171;
  display: flex;
}
.resume .resume_subtitle p {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  margin-left: 10px;
  color: white;
  font-weight: 600;
}
.resume .resume_title {
  margin: 50px 0px;
}
.resume .resume_title h2 {
  font-family: "Russo One", sans-serif;
  color: white;
  font-size: 70px;
}
.resume .resume_about {
  font-family: "Maven Pro", sans-serif;
  color: #908d96;
  line-height: 25px;
  font-size: 20px;
}
.resume .resume_about p {
  width: 80%;
}
.resume .resume_education .info {
  margin-bottom: 60px;
}
.resume .resume_education .info .info_title {
  color: white;
  font-family: "Montserrat", sans-serif;
  margin: 40px 0px;
  font-size: 30px;
}
.resume .resume_education .info .info_allContent .info_allContent_content {
  border-bottom: 1px solid #717171;
  width: 90%;
  padding: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.resume .resume_education .info .info_allContent .info_allContent_content .info_allContent_content-course h3 {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-top: 15px;
  font-family: "Maven Pro", sans-serif;
}
.resume .resume_education .info .info_allContent .info_allContent_content .info_allContent_content-course p {
  color: white;
  font-size: 14px;
  margin-top: 15px;
  font-family: "Maven Pro", sans-serif;
}
.resume .resume_education .info .info_allContent .info_allContent_content .info_allContent_content-date p {
  font-size: 12x;
  color: #717171;
  font-family: "Maven Pro", sans-serif;
}
.resume .resume_education .info .info_allContent .info_allContent_content .info_allContent_content-description {
  width: 30%;
  color: #908d96;
  font-size: 16px;
  font-family: "Maven Pro", sans-serif;
  margin-right: 50px;
}
.resume .resume_tools .resume_tools_title {
  color: white;
  font-family: "Montserrat", sans-serif;
  margin: 40px 0px;
  font-size: 30px;
}
.resume .resume_tools .resume_tools_tools {
  display: flex;
  flex-wrap: wrap;
}
.resume .resume_tools .resume_tools_tools .tool {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  padding: 30px 50px;
  border: 1px solid #908d96;
  margin-right: 30px;
  margin-bottom: 25px;
  border-radius: 20px;
}
.resume .resume_tools .resume_tools_tools .tool:hover {
  transform: scale(1.1);
}
.resume .resume_tools .resume_tools_tools .tool img {
  width: 100px;
}
.resume .resume_tools .resume_tools_tools .tool p {
  margin-top: 20px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-family: "Maven Pro", sans-serif;
}

@media (max-width: 1600px) {
  .resume .resume_subtitle p {
    font-size: 14px !important;
  }
  .resume .resume_title h2 {
    font-size: 55px !important;
  }
  .resume .resume_about p {
    font-size: 17px;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-course h3 {
    font-size: 25px !important;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-course p {
    font-size: 13px !important;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-date p {
    font-size: 12x;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-description {
    font-size: 13px !important;
  }
  .resume .resume_tools .resume_tools_title {
    font-size: 28px !important;
  }
  .resume .resume_tools .resume_tools_tools .tool {
    padding: 20px 40px;
  }
  .resume .resume_tools .resume_tools_tools .tool img {
    width: 80px;
  }
  .resume .resume_tools .resume_tools_tools .tool p {
    font-size: 18px;
  }
}
@media (max-width: 1400px) {
  .resume .resume_subtitle p {
    font-size: 14px !important;
  }
  .resume .resume_title h2 {
    font-size: 55px !important;
  }
  .resume .resume_about p {
    font-size: 17px;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-course h3 {
    font-size: 20px !important;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-course p {
    font-size: 10px !important;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-date p {
    font-size: 10px;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-description {
    font-size: 10px !important;
  }
  .resume .resume_tools .resume_tools_title {
    font-size: 28px !important;
  }
  .resume .resume_tools .resume_tools_tools .tool {
    padding: 20px 40px;
  }
  .resume .resume_tools .resume_tools_tools .tool img {
    width: 75px;
  }
  .resume .resume_tools .resume_tools_tools .tool p {
    font-size: 16px;
  }
}
@media (max-width: 990px) {
  .resume {
    padding-top: 100px;
    margin-top: 10px !important;
    margin-left: 5%;
  }
  .resume .resume_subtitle p {
    font-size: 16px !important;
  }
  .resume .resume_title h2 {
    font-size: 50px !important;
  }
  .resume .resume_about p {
    font-size: 16px;
  }
  .resume .resume_education .info .info_title {
    margin: 0px 0px;
    margin-top: 60px !important;
    font-size: 30px;
  }
  .resume .resume_education .info .info_allContent .info_allContent_content .info_allContent_content-course h3 {
    font-size: 18px !important;
  }
  .resume .resume_education .info .info_allContent .info_allContent_content .info_allContent_content-course p {
    font-size: 13px !important;
  }
  .resume .resume_education .info .info_allContent .info_allContent_content .info_allContent_content-date p {
    font-size: 12x;
  }
  .resume .resume_education .info .info_allContent .info_allContent_content .info_allContent_content-description {
    font-size: 10px !important;
  }
  .resume .resume_tools .resume_tools_title {
    font-size: 30px !important;
  }
  .resume .resume_tools .resume_tools_tools .tool {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 40px;
  }
  .resume .resume_tools .resume_tools_tools .tool img {
    width: 60px;
  }
  .resume .resume_tools .resume_tools_tools .tool p {
    font-size: 18px;
  }
}
@media (max-width: 750px) {
  .resume .info {
    margin-bottom: 60px;
  }
  .resume .info .info_title {
    color: white;
    font-family: "Montserrat", sans-serif;
    margin: 40px 0px;
    font-size: 30px;
  }
  .resume .info .info_allContent .info_allContent_content {
    display: flex;
    flex-direction: column;
    width: 95% !important;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-course {
    width: 100%;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-course h3 {
    font-size: 40px;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-course p {
    font-size: 14px;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-date {
    width: 100%;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-date p {
    font-size: 15px !important;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-description {
    margin-top: 20px;
    width: 100% !important;
    width: 50%;
  }
  .resume .info .info_allContent .info_allContent_content .info_allContent_content-description p {
    margin-left: 25px;
    font-size: 14px !important;
  }
  .resume .resume_tools_title {
    font-size: 28px !important;
  }
  .resume .resume_tools_tools .tool {
    padding: 30px 0vw !important;
    width: 38vw;
  }
  .resume .resume_tools_tools .tool img {
    width: 80px;
  }
  .resume .resume_tools_tools .tool p {
    font-size: 18px;
  }
}
.contact {
  padding-top: 150px;
  margin-top: 10px !important;
  margin-bottom: 40px;
}
.contact .contact_subtitle {
  background-color: #121212;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #717171;
  display: flex;
}
.contact .contact_subtitle p {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  margin-left: 10px;
  color: white;
  font-weight: 600;
}
.contact .contact_title {
  margin: 50px 0px;
}
.contact .contact_title h2 {
  width: 80%;
  font-family: "Russo One", sans-serif;
  color: white;
  font-size: 70px !important;
}
.contact form {
  width: 80%;
}
.contact form .contact_form_info {
  display: flex;
}
.contact form .contact_form_info input {
  font-family: "Maven Pro", sans-serif;
  color: white;
  font-size: 20px;
  background: none;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 20px;
  margin-bottom: 40px;
  margin-right: 30px;
  width: 90%;
  border: none;
  border-bottom: 3px solid #9171f8;
}
.contact form .contact_form_info input:focus {
  outline: none;
  border-bottom: 3px solid #5e43f3;
}
.contact form .contact_form_info input::-moz-placeholder {
  color: #908d96;
}
.contact form .contact_form_info input::placeholder {
  color: #908d96;
}
.contact form .contact_form_info .contact_form_info_names {
  display: flex;
  justify-content: space-between;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.contact form .contact_form_info .contact_form_info_contacts {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.contact form .contact_form_message textarea {
  resize: none;
  height: 200px;
  font-family: "Maven Pro", sans-serif;
  color: white;
  font-size: 20px;
  background: none;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 20px;
  margin-bottom: 40px;
  margin-right: 30px;
  width: 98%;
  border: none;
  border-bottom: 3px solid #9171f8;
}
.contact form .contact_form_message textarea:focus {
  outline: none;
  border-bottom: 3px solid #5e43f3;
}
.contact form .contact_form_message textarea::-moz-placeholder {
  color: #908d96;
}
.contact form .contact_form_message textarea::placeholder {
  color: #908d96;
}
.contact form button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 40px;
  border: none;
  border-radius: 15px;
  background: #5e43f3;
  color: white;
  font-size: 20px;
  font-family: "Maven Pro", sans-serif;
  cursor: pointer;
}
.contact form button img {
  margin-left: 20px;
  width: 30px;
}
.contact form button:hover {
  transform: translateY(-10px) !important;
}

@media (max-width: 1600px) {
  .contact .contact_subTitle p {
    font-size: 14px;
  }
  .contact .contact_title h2 {
    font-size: 55px !important;
  }
  .contact form input, .contact form textarea, .contact form button {
    font-size: 15px !important;
  }
}
@media (max-width: 1400px) {
  .contact .contact_title h2 {
    font-size: 45px !important;
  }
}
@media (max-width: 1200px) {
  .contact .contact_title h2 {
    font-size: 40px !important;
  }
}
@media (max-width: 990px) {
  .contact {
    padding-top: 100px;
    margin-top: 10px !important;
    margin-left: 5%;
  }
  .contact .contact_title h2 {
    font-size: 50px !important;
  }
  .contact .contact_form_info, .contact .contact_form_message {
    width: 90vw;
  }
}
@media (max-width: 750px) {
  .contact .contact_title h2 {
    font-size: 50px !important;
  }
  .contact_form_info {
    flex-direction: column;
    width: 100%;
  }
  .contact_form_info .contact_form_info_names, .contact_form_info .contact_form_info_contacts {
    align-items: flex-start !important;
    width: 108% !important;
    margin: 0px !important;
  }
}
footer {
  height: 30vh;
  width: 79%;
}
footer .footer_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
  border-top: 1px solid #908d96;
}
footer .footer_content h3 {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 18px;
  font-weight: bold;
}
footer .footer_content p {
  font-family: "Maven Pro", sans-serif;
  color: #908d96;
}

@media (max-width: 990px) {
  footer {
    margin-left: 5%;
    width: 88%;
  }
  footer .footer_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 40px;
    border-top: 1px solid #908d96;
  }
  footer .footer_content h3 {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  footer .footer_content p {
    font-family: "Maven Pro", sans-serif;
    color: #908d96;
  }
}
@media (max-width: 760px) {
  footer {
    width: 88vw !important;
  }
  footer .footer_content {
    justify-content: start;
    flex-direction: column;
    padding-top: 40px;
    border-top: 1px solid #908d96;
  }
  footer .footer_content div {
    margin-bottom: 20px;
  }
  footer .footer_content h3 {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  footer .footer_content a p {
    font-family: "Maven Pro", sans-serif;
    color: #908d96;
  }
  footer .footer_content a p:hover {
    color: #5e43f3 !important;
  }
}
.aboutMe {
  padding-top: 150px;
  margin-top: 10px !important;
}
.aboutMe .aboutMe_subtitle {
  background-color: #121212;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #717171;
  display: flex;
}
.aboutMe .aboutMe_subtitle p {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  margin-left: 10px;
  color: white;
  font-weight: 600;
}
.aboutMe .aboutMe_title {
  margin: 50px 0px;
}
.aboutMe .aboutMe_title h2 {
  font-family: "Russo One", sans-serif;
  color: white;
  font-size: 70px;
}
.aboutMe .aboutMe_content {
  display: flex;
}
.aboutMe .aboutMe_content .aboutMe_content_text {
  width: 65%;
  margin-right: 50px;
}
.aboutMe .aboutMe_content .aboutMe_content_text p {
  font-size: 17px;
  font-family: "Maven Pro", sans-serif;
  margin-bottom: 30px;
  color: white;
  line-height: 25px;
}
.aboutMe .aboutMe_content .aboutMe_content_infos .aboutMe_content_infos_info {
  margin-bottom: 25px;
  color: white;
}
.aboutMe .aboutMe_content .aboutMe_content_infos .aboutMe_content_infos_info h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}
.aboutMe .aboutMe_content .aboutMe_content_infos .aboutMe_content_infos_info p {
  font-size: 16px;
  font-family: "Maven Pro", sans-serif;
}
.aboutMe .aboutMe_download button {
  padding: 10px 25px;
  color: white;
  background: #5e43f3;
  font-size: 17px;
  font-family: "Maven Pro", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}
.aboutMe .aboutMe_download button:hover {
  transform: translateY(-10px);
}
.aboutMe .aboutMe_download button img {
  margin-left: 20px;
}
.aboutMe .aboutMe_cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}
.aboutMe .aboutMe_cards .techCard {
  margin-right: 10%;
  padding: 40px 20px 0px 20px;
  width: 40%;
  margin-right: 50px;
  border: 1px solid #717171;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
.aboutMe .aboutMe_cards .techCard .techCard_title h3 {
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  color: white;
  margin-bottom: 20px;
}
.aboutMe .aboutMe_cards .techCard .techCard_topics {
  display: flex;
  flex-wrap: wrap;
}
.aboutMe .aboutMe_cards .techCard .techCard_topics .techCard_topics_topic {
  padding: 10px 20px;
  font-size: 13px;
  color: white;
  border: 1px solid #717171;
  border-radius: 10px;
  font-family: "Maven Pro", sans-serif;
  margin-right: 25px;
  margin-bottom: 20px;
}
.aboutMe .aboutMe_cards .techCard .techCard_image {
  margin-top: 50px;
}
.aboutMe .aboutMe_cards .techCard .techCard_image img {
  width: 100%;
}

@media (max-width: 1600px) {
  .aboutMe .aboutMe_subTitle p {
    font-size: 14px;
  }
  .aboutMe .aboutMe_title h2 {
    font-size: 55px;
  }
  .aboutMe .aboutMe_content p {
    font-size: 15px !important;
    line-height: 20px !important;
  }
  .aboutMe .aboutMe_content_infos .aboutMe_content_infos_info h3 {
    font-size: 16px !important;
  }
  .aboutMe .aboutMe_content_infos .aboutMe_content_infos_info p {
    font-size: 14px !important;
  }
  .aboutMe .aboutMe_cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .aboutMe .aboutMe_cards .techCard .techCard_title h3 {
    font-size: 30px !important;
  }
  .aboutMe .aboutMe_cards .techCard .techCard_topics .techCard_topics_topic {
    font-size: 12px !important;
  }
}
@media (max-width: 1200px) {
  .aboutMe_content {
    display: flex;
    flex-direction: column !important;
    margin-bottom: 30px;
  }
  .aboutMe_content .aboutMe_content_text {
    width: 80% !important;
  }
  .aboutMe_cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .aboutMe_cards .techCard .techCard_title h3 {
    font-size: 28px !important;
  }
  .aboutMe_cards .techCard .techCard_topics .techCard_topics_topic {
    font-size: 10px !important;
  }
}
@media (max-width: 990px) {
  .aboutMe {
    padding-top: 100px;
    margin-top: 10px !important;
    margin-left: 5%;
  }
  .aboutMe .aboutMe_subTitle p {
    font-size: 16px;
  }
  .aboutMe .aboutMe_title h2 {
    font-size: 50px;
  }
  .aboutMe .aboutMe_cards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .aboutMe .aboutMe_cards .techCard {
    margin-right: 10%;
    width: 40%;
  }
  .aboutMe .aboutMe_cards .techCard .techCard_title h3 {
    font-size: 28px !important;
  }
  .aboutMe .aboutMe_cards .techCard .techCard_topics .techCard_topics_topic {
    font-size: 10px !important;
  }
}
@media (max-width: 750px) {
  .aboutMe .aboutMe_title h2 {
    font-size: 40px;
  }
  .aboutMe_cards {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap !important;
    margin-top: 50px;
  }
  .aboutMe_cards .techCard {
    margin-left: 2% !important;
    width: 90% !important;
  }
  .aboutMe_cards .techCard .techCard_title h3 {
    font-size: 28px !important;
  }
  .aboutMe_cards .techCard .techCard_topics .techCard_topics_topic {
    font-size: 10px !important;
  }
}
.popUpContainer {
  position: fixed;
  top: 0px;
  z-index: 10000000000;
  animation: 1s appear;
  padding-top: 18px;
  font-size: 13px;
  font-family: "Maven Pro", sans-serif;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popUpContainer .popUpContainer_popUp {
  border-radius: 10px;
  width: -moz-max-content;
  width: max-content;
  padding: 20px 30px;
  background-color: #5e43f3;
  color: white;
}

.invisiblePopUp {
  opacity: 0;
  animation: 1s disappear;
}

.invisible {
  display: none;
  animation: none !important;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}/*# sourceMappingURL=style.css.map */